"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TdeecalculatorCtrl = void 0;
const ng = window.angular;
const bmi_percentile_calculator_1 = require("@trifoia/bmi-percentile-calculator");
const round = function (value, exp) {
    return Number(value.toFixed(exp));
    if (typeof exp === 'undefined' || +exp === 0)
        return Math.round(value);
    value = +value;
    exp = +exp;
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
        return NaN;
    // Shift
    value = value.toString().split('e');
    value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
};
const defaultValues = {
    gender: 'male',
    activity: 1.375,
};
class TdeecalculatorCtrl {
    constructor($scope, $filter, ConfigService) {
        this.$scope = $scope;
        this.$filter = $filter;
        this.ConfigService = ConfigService;
        this.bmrFormula = 'Mifflin-St Jeor';
        this.bmrFormulaTrans = {
            'Mifflin-St Jeor': this.$filter('translate')('Mifflin-St Jeor'),
            'Harris-Benedict': this.$filter('translate')('Harris-Benedict'),
            'Revised Harris-Benedict': this.$filter('translate')('Revised Harris-Benedict'),
            'Katch-McArdle': this.$filter('translate')('Katch-McArdle'),
            'Schofield': this.$filter('translate')('Schofield'),
            'Oxford': this.$filter('translate')('Oxford'),
            'Cunningham': this.$filter('translate')('Cunningham'),
        };
        this.unit = JSON.parse(localStorage.getItem(`${this.constructor.name}_options`) || '{}').unit || 'metric';
        this.values = Object.assign(Object.assign({}, defaultValues), JSON.parse(localStorage.getItem(`${this.constructor.name}_values`) || '{}'));
        this.activities = [
            1.2, 1.375, 1.55, 1.725, 1.9
        ];
        this.activitiesTitle = [
            this.$filter('translate')('Little or no exercise'),
            this.$filter('translate')('Light exercise 1-3 times/week'),
            this.$filter('translate')('Moderate exercise 3-5 times/week'),
            this.$filter('translate')('Hard exercise 6-7 times/week'),
            this.$filter('translate')('Hard exercise 2 times/day'),
        ];
        this.cookieSettings = this.ConfigService.getCookieSettings();
        $scope.$watch('$ctrl.cookieSettings', (cookieSettings) => {
            this.ConfigService.setCookieSettings(cookieSettings);
        }, true);
        this.$scope.$watch('$ctrl.unit', (unit) => {
            let options = JSON.parse(localStorage.getItem(`${this.constructor.name}_options`) || '{}');
            localStorage.setItem(`${this.constructor.name}_options`, JSON.stringify(Object.assign(Object.assign({}, options), { unit })));
        });
    }
    $onInit() {
    }
    setBmrFormula(bmrFormula) {
        this.bmrFormula = bmrFormula;
        this.result = undefined;
    }
    setUnit(unit) {
        this.unit = unit;
        this.values = {
            gender: this.values.gender,
            age: this.values.age,
            activity: this.values.activity
        };
        this.result = undefined;
    }
    submitForm($form) {
        if ($form.$valid) {
            let result = {
                activity: this.values.activity
            };
            try {
                if (this.unit == 'imperial') {
                    this.values.weight = 0.453592 * this.values.weight_pound;
                    this.values.height = this.values.height_feet * 30.48 + this.values.height_inch * 2.54;
                }
                else if (this.unit == 'metric') {
                    this.values.weight_pound = 2.20462262185 * this.values.weight;
                    this.values.height_feet = Math.floor(this.values.height / 30.48);
                    this.values.height_inch = ((this.values.height / 30.48) - this.values.height_feet) * 12;
                }
                const total_height_inch = this.values.height_feet * 12 + this.values.height_inch;
                result.bmi = (0, bmi_percentile_calculator_1.metric)(this.values.weight, this.values.height / 100, this.values.gender == 'male' ? 'm' : 'f', this.values.age * 12);
                if (result.bmi.bmi < 16.0) {
                    result.bmi.current = this.$filter('translate')('Severely underweight');
                    // result.bmi.styleLeft = `${(result.bmi.bmi - 2.52) * 100 / (16 - 2.52)}%`
                }
                else if ((result.bmi.bmi >= 16.0) && (result.bmi.bmi <= 18.49)) {
                    result.bmi.current = this.$filter('translate')('Underweight');
                    // result.bmi.styleLeft = `${(result.bmi.bmi - 16) * 100 / (18.49 - 16)}%`
                }
                else if ((result.bmi.bmi >= 18.5) && (result.bmi.bmi <= 24.99)) {
                    result.bmi.current = this.$filter('translate')('Normal');
                    // result.bmi.styleLeft = `${(result.bmi.bmi - 18.5) * 100 / (24.99 - 18.5)}%`
                }
                else if ((result.bmi.bmi >= 25.0) && (result.bmi.bmi <= 29.99)) {
                    result.bmi.current = this.$filter('translate')('Overweight');
                    // result.bmi.styleLeft = `${(result.bmi.bmi - 25) * 100 / (29.99 - 25)}%`
                }
                else if ((result.bmi.bmi >= 30.0) && (result.bmi.bmi <= 34.99)) {
                    result.bmi.current = this.$filter('translate')('Obesity I');
                    // result.bmi.styleLeft = `${(result.bmi.bmi - 30) * 100 / (34.99 - 30)}%`
                }
                else if ((result.bmi.bmi >= 35.0) && (result.bmi.bmi <= 39.99)) {
                    result.bmi.current = this.$filter('translate')('Obesity II');
                    // result.bmi.styleLeft = `${(result.bmi.bmi - 35) * 100 / (39.99 - 35)}%`
                }
                else if (result.bmi.bmi >= 40.0) {
                    result.bmi.current = this.$filter('translate')('Obesity III');
                    // result.bmi.styleLeft = `${(result.bmi.bmi - 40) * 100 / (755.56 - 40)}%`
                }
                const kgToLbs = this.unit == 'imperial' ? 2.20462262185 : 1;
                if (this.values.gender == 'male') {
                    result.idealWeight = {
                        hamwi: (48 + (2.7 * (total_height_inch - 60))) * kgToLbs,
                        devine: (50 + (2.3 * (total_height_inch - 60))) * kgToLbs,
                        robinson: (52 + (1.9 * (total_height_inch - 60))) * kgToLbs,
                        miller: (56.2 + (1.41 * (total_height_inch - 60))) * kgToLbs,
                    };
                    switch (this.bmrFormula) {
                        case "Mifflin-St Jeor": {
                            result.bmr = 10 * this.values.weight + 6.25 * this.values.height - 5 * this.values.age + 5;
                            break;
                        }
                        case "Harris-Benedict": {
                            result.bmr = 66.5 + 13.75 * this.values.weight + 5.003 * this.values.height - 6.755 * this.values.age;
                            break;
                        }
                        case "Revised Harris-Benedict": {
                            result.bmr = 88.362 + 13.397 * this.values.weight + 4.799 * this.values.height - 5.677 * this.values.age;
                            break;
                        }
                        case "Katch-McArdle": {
                            const lbm = this.values.weight - this.values.weight * this.values.body_fat / 100;
                            result.bmr = 370 + (21.6 * lbm);
                            break;
                        }
                        case "Schofield": {
                            if (this.values.age < 30) {
                                result.bmr = 15.057 * this.values.weight + 692.2;
                            }
                            else if ((this.values.age >= 30) && (this.values.age < 60)) {
                                result.bmr = 11.472 * this.values.weight + 873.1;
                            }
                            else if (this.values.age >= 60) {
                                result.bmr = 11.711 * this.values.weight + 587.7;
                            }
                            break;
                        }
                        case "Oxford": {
                            if (this.values.age < 3) {
                                result.bmr = 61 * this.values.weight - 33.7;
                            }
                            else if ((this.values.age >= 3) && (this.values.age < 10)) {
                                result.bmr = 23.3 * this.values.weight + 514;
                            }
                            else if ((this.values.age >= 10) && (this.values.age < 18)) {
                                result.bmr = 18.4 * this.values.weight + 581;
                            }
                            else if ((this.values.age >= 18) && (this.values.age < 30)) {
                                result.bmr = 16 * this.values.weight + 545;
                            }
                            else if ((this.values.age >= 30) && (this.values.age < 60)) {
                                result.bmr = 14.2 * this.values.weight + 593;
                            }
                            else if (this.values.age >= 60) {
                                result.bmr = 13.5 * this.values.weight + 514;
                            }
                            break;
                        }
                        case "Cunningham": {
                            const lbm = this.values.weight - this.values.weight * this.values.body_fat / 100;
                            result.bmr = 500 + (22 * lbm);
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
                else if (this.values.gender == 'female') {
                    result.idealWeight = {
                        hamwi: 45.5 + (2.2 * (total_height_inch - 60)) * kgToLbs,
                        devine: 45.5 + (2.3 * (total_height_inch - 60)) * kgToLbs,
                        robinson: 49 + (1.7 * (total_height_inch - 60)) * kgToLbs,
                        miller: 53.1 + (1.36 * (total_height_inch - 60)) * kgToLbs,
                    };
                    switch (this.bmrFormula) {
                        case "Mifflin-St Jeor": {
                            result.bmr = 10 * this.values.weight + 6.25 * this.values.height - 5 * this.values.age - 161;
                            break;
                        }
                        case "Harris-Benedict": {
                            result.bmr = 655.1 + 9.563 * this.values.weight + 1.85 * this.values.height - 4.676 * this.values.age;
                            break;
                        }
                        case "Revised Harris-Benedict": {
                            result.bmr = 447.593 + 9.247 * this.values.weight + 3.098 * this.values.height - 4.33 * this.values.age;
                            break;
                        }
                        case "Katch-McArdle": {
                            const lbm = this.values.weight - this.values.weight * this.values.body_fat / 100;
                            result.bmr = 370 + (21.6 * lbm);
                            break;
                        }
                        case "Schofield": {
                            if (this.values.age < 30) {
                                result.bmr = 14.818 * this.values.weight + 486.6;
                            }
                            else if ((this.values.age >= 30) && (this.values.age < 60)) {
                                result.bmr = 8.126 * this.values.weight + 845.6;
                            }
                            else if (this.values.age >= 60) {
                                result.bmr = 9.082 * this.values.weight + 658.5;
                            }
                            break;
                        }
                        case "Oxford": {
                            if (this.values.age < 3) {
                                result.bmr = 58.9 * this.values.weight - 23.1;
                            }
                            else if ((this.values.age >= 3) && (this.values.age < 10)) {
                                result.bmr = 20.1 * this.values.weight + 507;
                            }
                            else if ((this.values.age >= 10) && (this.values.age < 18)) {
                                result.bmr = 11.1 * this.values.weight + 761;
                            }
                            else if ((this.values.age >= 18) && (this.values.age < 30)) {
                                result.bmr = 13.1 * this.values.weight + 558;
                            }
                            else if ((this.values.age >= 30) && (this.values.age < 60)) {
                                result.bmr = 9.74 * this.values.weight + 694;
                            }
                            else if (this.values.age >= 60) {
                                result.bmr = 10.1 * this.values.weight + 569;
                            }
                            break;
                        }
                        case "Cunningham": {
                            const lbm = this.values.weight - this.values.weight * this.values.body_fat / 100;
                            result.bmr = 500 + (22 * lbm);
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
                if (result.bmr != undefined) {
                    result.idealWeightValues = Object.values(result.idealWeight).sort();
                    result.bmr = round(result.bmr, 0);
                    result.bmrFormatted = this.$filter('myNumber')(result.bmr, 0);
                    result.tdee = round(result.bmr * result.activity, 0);
                    result.tdeeFormatted = this.$filter('myNumber')(result.tdee, 0);
                    result.tdeeCuttingFormatted = this.$filter('myNumber')(result.tdee - 500, 0);
                    result.tdeeBulkingFormatted = this.$filter('myNumber')(result.tdee + 500, 0);
                    result.tdeeWeekFormatted = this.$filter('myNumber')(result.tdee * 7, 0);
                    result.tdeeTable = this.activities.map((activity, index) => {
                        const tdee = round(result.bmr * activity, 0);
                        return {
                            activity: activity,
                            title: this.activitiesTitle[index],
                            tdee: tdee,
                            tdeeFormatted: this.$filter('myNumber')(tdee),
                        };
                    });
                    result.loseWeightWarning = false;
                    result.loseWeightTable = [0.25, 0.5, 1].map((kg) => {
                        const calories = result.tdee - (kg * 1000);
                        if (calories <= 1500) {
                            result.loseWeightWarning = true;
                        }
                        return {
                            kg: kg,
                            lb: kg * 2,
                            value: this.unit == 'metric' ? kg : (kg * 2),
                            calories: calories,
                            percent: round(calories * 100 / result.tdee, 0)
                        };
                    });
                    result.gainWeightTable = [0.25, 0.5, 1].map((kg) => {
                        const calories = result.tdee + (kg * 1000);
                        return {
                            kg: kg,
                            lb: kg * 2,
                            value: this.unit == 'metric' ? kg : (kg * 2),
                            calories: calories,
                            percent: round(calories * 100 / result.tdee, 0)
                        };
                    });
                    result.maintenance = this.getMacronutrients(result.tdee);
                    result.cutting = this.getMacronutrients(result.tdee - 500);
                    result.bulking = this.getMacronutrients(result.tdee + 500);
                    // console.log(result.tdee)
                    // console.log(result.maintenance)
                    // console.log(result.cutting)
                    // console.log(result.bulking)
                }
            }
            catch (e) {
                console.error(e);
            }
            console.log(result, this.values);
            this.result = result;
        }
    }
    getMacronutrients(tdee) {
        const groupTitles = [
            this.$filter('translate')('Moderate-carb diet'),
            this.$filter('translate')('Low-carb diet'),
            this.$filter('translate')('High-carb diet'),
        ];
        const titles = [
            this.$filter('translate')('Protein'),
            this.$filter('translate')('Fats'),
            this.$filter('translate')('Carbs'),
        ];
        return [
            [30, 35, 35],
            [40, 40, 20],
            [30, 20, 50]
        ].map((group, index) => {
            const groupTitle = groupTitles[index];
            const groupRatio = group.join('/');
            return group.map((percent, index) => {
                let gram = 4;
                if (index === 1) {
                    // fats
                    gram = 9;
                }
                return {
                    groupTitle: groupTitle,
                    groupRatio: groupRatio,
                    percent: percent,
                    title: titles[index],
                    weight: (tdee * percent / 100) / gram
                };
            });
        });
    }
    getWeightUnit() {
        return this.unit == 'metric' ? this.$filter('translate')('kg') : this.$filter('translate')('lb');
    }
    clear($form) {
        if ($form != undefined) {
            $form.$setPristine();
            $form.$setUntouched();
        }
        this.values = Object.assign(Object.assign({}, defaultValues), { activity: this.values.activity });
        this.result = undefined;
    }
}
exports.TdeecalculatorCtrl = TdeecalculatorCtrl;
TdeecalculatorCtrl.$inject = ['$scope', '$filter', 'ConfigService'];
const appModule = ng.module('app');
appModule.component('gameTdeecalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: TdeecalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('tdeecalculator/');
    }]);
